import { defineStore } from 'pinia'
import Tracker from '@openreplay/tracker'

// import posthog from 'posthog-js'
import { useLocalStorage } from '@vueuse/core'
import { APP_VERSION } from '@/config/constantes'

type Job = {
  _created: string
  positionId: string
  startDate: string
  endDate: string
  isAlumni: boolean
  isStartDate: boolean
  officeId: string
  entityId: string
  managerId: string
  managerId2: string | null
  rate: number
  startDateLOA: string | null
  endDateLOA: string | null
  _id: string
}

type Degree = {
  _created: string
  degree: string
  endYear: number
  description: string
  isMain: boolean
  _id: string
}

type Nationality = {
  countryId: string
  passportExpirationDate: string | null
  passportIssueDate: string | null
  _id: string
}

type Display = {
  FR: string
  EN: string
}

type RadioArray = {
  _id: number
  display: Display
}

type SubAccess = {
  _id: number
  isActive: boolean
  isRadio: boolean
  radioArray: RadioArray[]
  radioValue?: number
  checkboxSource?: string
  checkboxArray?: any[]
  display: Display
  isAccess: boolean
}

type Module = {
  _id: number
  isActive: boolean
  moduleId: number
  isAccess: boolean
  subAccess: SubAccess[]
}

type AdminModule = Module
type ResourceModule = Module

type HRModule = {
  moduleId: 'HR'
  resourceOverviewOfficeZoneIds: any[]
  resourceOverviewCompanyZoneIds: any[]
  wageOverviewOfficeZoneIds: any[]
  wageOverviewCompanyZoneIds: any[]
  onboardingAccess: boolean
  alumniAccess: boolean
  statsAccess: boolean
  settingsAccess: boolean
  resourceOverview: string
  hidePersonnalData: boolean
  tagSkills: boolean
  resourceProfileAccess: boolean
  wageAccess: boolean
  wageOverview: string
  isAccess: boolean
  resourceOverviewOfficeZone: any[]
}

type DashboardModule = {
  moduleId: 'DASHBOARD'
  isAccess: boolean
  productionAccess: boolean
  salesAccess: boolean
  cashFlowAccess: boolean
  resourceAccess: boolean
}

type ProjectModule = {
  moduleId: 'PROJECT'
  projectOverviewOfficeZoneIds: any[]
  projectOverviewCompanyZoneIds: any[]
  isAccess: boolean
  createAccess: boolean
  planningResourceAccess: boolean
  planningResourceScopeAll: boolean
  statsAccess: boolean
  settingsAccess: boolean
  projectOverview: string
  overviewOnlyPublic: boolean
}

type TimesheetModule = {
  moduleId: 'TIMESHEET'
  isAccess: boolean
  showAll: boolean
}

type GlobalSettings = {
  userAccess: boolean
  profileAccess: boolean
  canGrantSuperAdminAccess: boolean
}

type LeaveModule = {
  moduleId: 'LEAVE'
  isAccess: boolean
  showAll: boolean
  calendarAccess: boolean
  requestAccess: boolean
  editAllLeaves: boolean
  canSelfValidate: boolean
  canChooseValidator: boolean
  newRequestAccess: boolean
  leaveValidator: boolean
  bulkCreateAccess: boolean
}

type EvaluationModule = {
  moduleId: 'EVALUATION'
  evaluationOverviewOfficeZoneIds: any[]
  isAccess: boolean
  showAll: boolean
  evaluationOverview: string
  create360: boolean
  createPeriod: boolean
  createProject: boolean
  canEditEvaluation: boolean
}

type RecruitmentModule = {
  recruitmentOverviewOfficeZoneIds: any[]
  moduleId: 'RECRUITMENT'
  isAccess: boolean
  screeningAccess: boolean
  recruitmentOverview: string
  canGrantAdminAccess: boolean
  pipegAccess: boolean
  jobBoardAccess: boolean
  candidateBaseAccess: boolean
  candidateHistoryAccess: boolean
  canEditNotManaged: boolean
  jobBoardAdminAccess: boolean
  canDeleteInterview: boolean
  statsAccess: boolean
  canDecideInsteadOfRecruiterEvaluation: boolean
  canReopenEvaluation: boolean
  canDecideProcessResult: boolean
}

type ExportModule = {
  moduleId: 'EXPORT'
  isAccess: boolean
  canExportEvaluation: boolean
  canExportResource: boolean
}

type Modules = {
  hr: HRModule
  dashboard: DashboardModule
  project: ProjectModule
  timesheet: TimesheetModule
  globalSettings: GlobalSettings
  leave: LeaveModule
  evaluation: EvaluationModule
  recruitment: RecruitmentModule
  export: ExportModule
}

type UserProfile = {
  _id: string
  isDeleted: boolean
  language: string
  isAdminProfile: boolean
  isSubAdminProfile: boolean
  isResourceProfile: boolean
  isCandidateProfile: boolean
  adminModules: AdminModule[]
  resourceModules: ResourceModule[]
  _firmId: string
  authorId: string
  display: string
  _created: string
  __v: number
  modules: Modules
}

type CurrentJob = {
  _created: string
  positionId: string
  startDate: string
  isAlumni: boolean
  isStartDate: boolean
  officeId: string
  entityId: string
  managerId: string
  managerId2: string | null
  rate: number
  startDateLOA: string | null
  endDateLOA: string | null
  _id: string
  position: {
    _id: string
    isDeleted: boolean
    authorId: string
    _firmId: string
    modifiedBy: string[]
    order: number | null
    display: string
    acronym: string
    isTemp: boolean
    isExecutive: boolean
    visibleStaffing: boolean
    visibleJobing: boolean
    visibleTimesheet: boolean
    isBudgetable: boolean
    categoryRating: number | null
    skillRating: number | null
    upperJobs: string[]
    rates: number[]
    _created: string
    skills: string[]
    __v: number
  }
  office: {
    _id: string
    isDeleted: boolean
    countryId: string
    timeZone: number
    display: string
    _firmId: string
    __v: number
  }
}

type Anonymize = {
  anonymizeDate: string | null
  anonymizeByR: string | null
  anonymizeByU: string | null
}

type ApplicationSettings = Record<string, unknown>

type User = {
  _id: string
  shortcuts: string[]
  isOnboarded: boolean
  isOnboardedAdmin: boolean
  role: string
  picture: string
  countryId: string
  officeId: string
  isDeleted: boolean
  candidateSource: number
  invitedBy: string
  recommendedBy: string | null
  assistants: string[]
  modifiedBy: string[]
  resourceType: number
  isActive: number
  dataDuration: number
  departureDate: string | null
  isDepartureWanted: boolean
  birthdate: string
  birthPlace: string | null
  maritalStatus: string | null
  socialSecurity: string | null
  addressLine1: string
  addressLine2: string
  addressZipcode: string
  addressCity: string
  addressCountry: string
  phoneNumber1: string
  phoneNumber2: string | null
  mailPersonal: string
  wishList: string[]
  userGroups: string[]
  index: string
  isActivated: boolean
  CGUvalidatedDate: string
  mailMod: number[]
  jobs: Job[]
  firstname: string
  lastname: string
  accessProfile: string
  _firmId: string
  firm: {
    _id: string
    company: string
    isBL: boolean
    isWL: boolean
    squareLogo: string
    picture: string
  }
  _created: string
  recruitmentDate: string
  experiences: string[]
  projects: string[]
  skills: string[]
  degrees: Degree[]
  documents: string[]
  comments: string[]
  userId: string
  __v: number
  gender: number
  username: string
  nationality: string
  givenLastname: string
  maidenname: string
  nationalities: Nationality[]
  zoneId: string[]
  anonymize: Anonymize
  applicationSettings: ApplicationSettings
  availableProfileIds: string[]
  companyZoneId: string[]
  currentJob: CurrentJob
  profile: UserProfile
}

type RootObject = {
  authRefreshTryed: boolean
  user: User
  token: string
}

export const useUserStore = defineStore('user', {
  state: (): RootObject => {
    return {
      authRefreshTryed: false,
      user: undefined,
      token: undefined,
    }
  },
  actions: {
    connection({ user, token }) {
      this.user = user
      this.token = token
      this.authRefreshTryed = false
      if (!window?.location.host.includes('127.0.0.1') && !window?.location.host.includes('localhost')) {
        if (user?.userId) {
          const tracker = new Tracker({
            projectKey: 'miGUGdJfXWzTEogA7AFc',
            ingestPoint: 'https://openreplay.bc.direct/ingest',
          })

          tracker.setUserID(user.user.email)
          tracker.setMetadata('resourceId', user._id)
          tracker.setMetadata('name', `${user.lastname} ${user.firstname}`)
          tracker.setMetadata('profile', user.profile.display)
          tracker.setMetadata('firm', user.firm.company)
          tracker.setMetadata('_firmId', user._firmId)
          tracker.setMetadata('picture', user.picture)
          tracker.setMetadata('release', APP_VERSION)
          const isSandbox = window?.location.host.includes('sandbox') || false
          const userProperties = {
            version: APP_VERSION,
            email: user.user.email,
            resourceId: user._id,
            firstname: user.firstname,
            lastname: user.lastname,
            profileId: user.accessProfile,
            profile: user.profile?.display,
          }
          if (isSandbox) {
            // posthog.identify(
            //   user.user.email, // Required. Replace 'distinct_id' with your user's unique identifier
            //   userProperties,
            // )
          }
        }
      }
    },
    disconnection() {
      this.user = undefined
      this.token = undefined
    },
  },
  persist: true,
})
